const isProd = (process.env.STAGE_NAME || process.env.NEXT_PUBLIC_STAGE_NAME || 'dev').includes('prod');
export default {
    isProd,
    env: process.env.NODE_ENV,
    stage: process.env.STAGE_NAME || process.env.NEXT_PUBLIC_STAGE_NAME || 'dev',
    mode: process.env.MODE,
    VERSION: process.env.VERSION || 'NA',
    autohostApiEndpoint:
        process.env.AUTOHOST_API_ENDPOINT ||
        process.env.NEXT_PUBLIC_AUTOHOST_API_ENDPOINT ||
        `https://api.autohost.${isProd ? 'ai' : 'ca'}`,
    cookieName: process.env.AUTOHOST_COOKIE_NAME || process.env.NEXT_PUBLIC_AUTOHOST_COOKIE_NAME || 'autohost',
    SEGMENT_API_KEY: process.env.SEGMENT_API_KEY || process.env.NEXT_PUBLIC_SEGMENT_API_KEY,
    ROLLBAR_API_KEY: process.env.ROLLBAR_API_KEY || process.env.NEXT_PUBLIC_ROLLBAR_API_KEY,
    GUEST_PORTAL_BASE_URL: isProd ? 'https://verifyhub.org' : 'https://gueststay.in',
    STRIPE_API_KEY:
        process.env.STRIPE_API_KEY ||
        process.env.NEXT_PUBLIC_STRIPE_API_KEY ||
        'pk_test_q193wO9AIBLlYTMAx7bBIFfD00duM34H1d',
    STRIPE_CLIENT_ID:
        process.env.STRIPE_CLIENT_ID ||
        process.env.NEXT_PUBLIC_STRIPE_CLIENT_ID ||
        'ca_GPLdjOWiVRSyjrNnzCLKEyoeX2u5i308',
    DATADOG_RUM_APP_ID: process.env.DATADOG_RUM_APP_ID
        ? process.env.DATADOG_RUM_APP_ID
        : isProd
        ? '35fa3f47-e767-47b6-a220-1f409ad34d37'
        : '13b34e02-152d-4d91-9412-abdf57cfd565',
    DATADOG_RUM_CLIENT_TOKEN: process.env.DATADOG_RUM_CLIENT_TOKEN
        ? process.env.DATADOG_RUM_CLIENT_TOKEN
        : isProd
        ? 'pub71ce4b98535e85751dbba952a3769d45'
        : 'pub829d695fb0b5c5cde60735729b813e4f',
    CLOUDBEDS_API_CLIENT_ID: process.env.CLOUDBEDS_API_CLIENT_ID,
    HOSTFULLY_API_CLIENT_ID: process.env.NEXT_PUBLIC_HOSTFULLY_API_CLIENT_ID
        || (isProd
        ? 'j2JLo2aLQImw1NvIGNu06RmNtWzFC5eq'
        : 'FrwTBIiDR8N3dgSfPUNej4opl6b3TI8V'),
    FLAGSMITH_ENVIRONMENT_KEY: process.env.FLAGSMITH_ENVIRONMENT_KEY || '',
    FLAGSMITH_API_URL: process.env.FLAGSMITH_API_URL,
};

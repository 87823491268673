import flagsmith from "flagsmith/isomorphic";
import { LOGIN_CHALLENGE_SUCCESS, LOGIN_SUCCESS, LOGOUT_SUCCESS, SET_AUTH } from "../../reducers/auth";

export const authMiddleware = (store) => (next) => async (action) => {
    // Handle identifying the user to external services (like Flagsmith for feature flagging)
    if (action.type === LOGIN_SUCCESS || action.type === LOGIN_CHALLENGE_SUCCESS) {
        // Identify with login actions
        const userId = action.payload.id;
        await flagsmith.identify(userId);
        console.log('flagsmith ID', action.type, userId)
    }
    if (action.type === SET_AUTH) {
        // Identify already logged in user
        const userId = action.auth.user.id;
        await flagsmith.identify(userId);
        console.log('flagsmith ID', action.type, userId)
    }
    if (action.type === LOGOUT_SUCCESS) {
        flagsmith.logout();
    }
    return next(action);
};
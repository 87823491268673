const GET = 'autohost/reservations/GET';
const GET_SUCCESS = 'autohost/reservations/GET_SUCCESS';
const GET_FAIL = 'autohost/reservations/GET_FAIL';
const GET_STATS = 'autohost/reservations/GET_STATS';
const GET_STATS_SUCCESS = 'autohost/reservations/GET_STATS_SUCCESS';
const GET_STATS_FAIL = 'autohost/reservations/GET_STATS_FAIL';
const GET_LOGS = 'autohost/reservations/GET_LOGS';
const GET_LOGS_SUCCESS = 'autohost/reservations/GET_LOGS_SUCCESS';
const GET_LOGS_FAIL = 'autohost/reservations/GET_LOGS_FAIL';
const LOAD = 'autohost/reservations/LOAD';
const LOAD_SUCCESS = 'autohost/reservations/LOAD_SUCCESS';
const LOAD_FAIL = 'autohost/reservations/LOAD_FAIL';
const SEARCH = 'autohost/reservations/SEARCH';
const SEARCH_SUCCESS = 'autohost/reservations/SEARCH_SUCCESS';
const SEARCH_FAIL = 'autohost/reservations/SEARCH_FAIL';
const CLEAR_SEARCH = 'autohost/reservations/CLEAR_SEARCH';
const ANALYZE_PROTECT = 'autohost/reservations/ANALYZE_PROTECT';
const ANALYZE_PROTECT_SUCCESS = 'autohost/reservations/ANALYZE_PROTECT_SUCCESS';
const ANALYZE_PROTECT_FAIL = 'autohost/reservations/ANALYZE_PROTECT_FAIL';
const DOWNLOAD_REPORT = 'autohost/reservations/DOWNLOAD_REPORT';
const DOWNLOAD_REPORT_SUCCESS = 'autohost/reservations/DOWNLOAD_REPORT_SUCCESS';
const DOWNLOAD_REPORT_FAIL = 'autohost/reservations/DOWNLOAD_REPORT_FAIL';
const GENERATE_REPORT = 'autohost/reservations/GENERATE_REPORT';
const GENERATE_REPORT_SUCCESS = 'autohost/reservations/GENERATE_REPORT_SUCCESS';
const GENERATE_REPORT_FAIL = 'autohost/reservations/GENERATE_REPORT_FAIL';
const CONTROL_GUEST_PORTAL_SCREEN = 'autohost/reservations/CONTROL_GUEST_PORTAL_SCREEN';
const CONTROL_GUEST_PORTAL_SCREEN_SUCCESS = 'autohost/reservations/CONTROL_GUEST_PORTAL_SCREEN_SUCCESS';
const CONTROL_GUEST_PORTAL_SCREEN_FAIL = 'autohost/reservations/CONTROL_GUEST_PORTAL_SCREEN_FAIL';
const RESOLVE_ADDRESS = 'autohost/reservations/RESOLVE_ADDRESS';
const RESOLVE_ADDRESS_SUCCESS = 'autohost/reservations/RESOLVE_ADDRESS_SUCCESS';
const RESOLVE_ADDRESS_FAIL = 'autohost/reservations/RESOLVE_ADDRESS_FAIL';
const SET_VERIFICATION_STATUS = 'autohost/reservations/SET_VERIFICATION_STATUS';
const SET_VERIFICATION_STATUS_SUCCESS = 'autohost/reservations/SET_VERIFICATION_STATUS_SUCCESS';
const SET_VERIFICATION_STATUS_FAIL = 'autohost/reservations/SET_VERIFICATION_STATUS_FAIL';
const GET_IMAGE = 'autohost/reservations/GET_IMAGE';
const GET_IMAGE_SUCCESS = 'autohost/reservations/GET_IMAGE_SUCCESS';
const GET_IMAGE_FAIL = 'autohost/reservations/GET_IMAGE_FAIL';
const UPDATE_BATCH = 'autohost/reservations/UPDATE_BATCH';
const UPDATE_BATCH_POP = 'autohost/reservations/UPDATE_BATCH_POP';
const UPDATE_BATCH_PATCH = 'autohost/reservations/UPDATE_BATCH_PATCH';

import {getTableRowsPerPage} from '../libs/personalize';
import moment from 'moment';

const initialState = {
    loading: false,
    loaded: false,
    reservation: {},
    reservations: [],
    batch: [],
    stats: {},
    logs: {
        reservationId: null,
    },
    pagination: {
        sort: 'check_in_date:asc',
        from: 0,
        total: 0,
    },
    task: {
        error: null
    },
    search: []
};

let updatedReservations;
const calcPages = (total, size) => Math.ceil(total / size)
const calcPage = (total, size, from) => Math.ceil(total / size) - Math.ceil((total - from) / size)

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                reservation: action.payload,
                error: null
            };
        case GET_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case GET_LOGS:
            return state;
        case GET_LOGS_SUCCESS:
            return {
                ...state,
                logs: {
                    reservationId: action.id,
                    pagination: {
                        ...action.pagination,
                        total: action.payload.total,
                        from: action.payload.from,
                        size: action.payload.size,
                    },
                    items: action.payload.items,
                    error: null
                }
            };
        case GET_LOGS_FAIL:
            return {
                ...state,
                logs: {
                    reservationId: action.id,
                    items: [],
                    error: action.error
                }
            };
        case GET_STATS_SUCCESS:
            return {
                ...state,
                stats: action.payload
            };
        case LOAD:
            return {
                ...state,
                selected: null,
                loading: true
            };
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                reservations: action.payload.items,
                pagination: {
                    ...action.pagination,
                    sort: action.pagination.sort || state.pagination.sort,
                    total: Number(action.payload.total),
                    size: Number(action.payload.size),
                    from: Number(action.payload.from),
                    pages: calcPages(action.payload.total, action.payload.size),
                    page: calcPage(action.payload.total, action.payload.size, action.payload.from),
                },
                error: null
            };
        case LOAD_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case CONTROL_GUEST_PORTAL_SCREEN_SUCCESS:
            return {
                ...state,
                reservation: {
                    ...state.reservation,
                    ...action.payload
                },
                error: null
            };
        case GENERATE_REPORT_SUCCESS:
            return {
                ...state,
                task: {...action.payload}
            };
        case GENERATE_REPORT_FAIL:
            return {
                ...state,
                task: {
                    error: action.error
                }
            };
        case DOWNLOAD_REPORT_SUCCESS:
            return {
                ...state,
                task: {
                    error: null
                }
            };
        case SET_VERIFICATION_STATUS_SUCCESS:
            if (typeof action.index === 'number') {
                updatedReservations = JSON.parse(JSON.stringify(state.reservations)) // fresh copy to avoid mutations
                updatedReservations[action.index] = {
                    ...updatedReservations[action.index],
                    protect: {
                        ...updatedReservations[action.index].protect,
                        verification_status: action.payload
                    }
                }
                return {
                    ...state,
                    reservations: updatedReservations
                };
            }
            return {
                ...state,
                reservation: {
                    ...state.reservation,
                    protect: {
                        ...state.reservation.protect,
                        verification_status: action.payload
                    }
                }
            };
        case SEARCH_SUCCESS:
            return {
                ...state,
                search: action.payload.items
            };
        case CLEAR_SEARCH:
            return {
                ...state,
                search: []
            };
        case UPDATE_BATCH:
            return {
                ...state,
                batch: action.ids,
                error: null
            };
        case UPDATE_BATCH_POP:
            return {
                ...state,
                batch: (state.batch || []).filter(id => id !== action.id),
            };
        case UPDATE_BATCH_PATCH:
            updatedReservations = JSON.parse(JSON.stringify(state.reservations)) // fresh copy to avoid mutations
            updatedReservations[action.index] = {
                ...updatedReservations[action.index],
                ...action.patch,
            }
            return {
                ...state,
                reservations: updatedReservations
            };
        default:
            return state;
    }
}

export function getReservation(id) {
    return {
        types: [GET, GET_SUCCESS, GET_FAIL],
        id: id,
        promise: (client) => client.get(`/reservation/get/${id}`)
    };
}

export function getReservationImage(id, image = 'any') {
    return {
        types: [GET_IMAGE, GET_IMAGE_SUCCESS, GET_IMAGE_FAIL],
        id: id,
        promise: (client) => client.get(`/reservation/image/${id}?image=${image}`)
    };
}

export function rotateReservationImage(id, image = 'any') {
    return {
        types: [GET_IMAGE, GET_IMAGE_SUCCESS, GET_IMAGE_FAIL],
        id: id,
        promise: (client) => client.get(`/reservation/rotateImage/${id}/?image=${image}&sync=1`)
    };
}

export function getProtectLogs(id, pagination) {
    const query = {
        size: 100,
        from: 0,
        ...pagination,
    }
    const querystring = Object.keys(query).map(k => `${k}=${query[k]}`).join('&')
    return {
        types: [GET_LOGS, GET_LOGS_SUCCESS, GET_LOGS_FAIL],
        id: id,
        pagination: query,
        promise: (client) => client.get(`/reservation/protectLog/${id}?${querystring}`)
    };
}

export function loadReservations(pagination) {
    const query = {
        startDate: moment().format("YYYY-MM-DD"),
        from: 0,
        size: getTableRowsPerPage('reservations'),
        status: 'CONFIRMED',
        ...pagination,
    }
    const querystring = Object.keys(query).map(k => `${k}=${query[k]}`).join('&')
    return {
        types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
        pagination: query,
        promise: (client) => client.get(`/reservation/search?${querystring}`)
    };
}

export function getStats(params) {
    const query = {
        ...params,
    }
    const querystring = Object.keys(query).map(k => `${k}=${query[k]}`).join('&')
    return {
        types: [GET_STATS, GET_STATS_SUCCESS, GET_STATS_FAIL],
        promise: (client) => client.get(`/reservation/stats?${querystring}`)
    };
}

export function sendToAutohostProtect(reservation, riskType) {
    let data = {
        reservation: reservation,
        riskType: riskType,
        source: 'dashboard'
    };
    return {
        types: [ANALYZE_PROTECT, ANALYZE_PROTECT_SUCCESS, ANALYZE_PROTECT_FAIL],
        promise: (client) => client.post(`/protect/webhook`, {data})
    };
}

export function generateReport(id, type = 'guest-portal', refresh = false) {
    return {
        types: [GENERATE_REPORT, GENERATE_REPORT_SUCCESS, GENERATE_REPORT_FAIL],
        id: id,
        promise: (client) => client.get(`/user/reports/generate/${type}/${id}${(refresh) ? '?refresh=1': ''}`)
    };
}

export function downloadReport(id, type = 'guest-portal') {
    return {
        types: [DOWNLOAD_REPORT, DOWNLOAD_REPORT_SUCCESS, DOWNLOAD_REPORT_FAIL],
        id: id,
        promise: (client) => client.get(`/user/reports/generate/${type}/${id}?download=1`, {
            options: {
                responseType: 'blob'
            }
        })
    };
}

export function controlGuestPortalScreen(reservationId, screen, state) {
    return {
        types: [CONTROL_GUEST_PORTAL_SCREEN, CONTROL_GUEST_PORTAL_SCREEN_SUCCESS, CONTROL_GUEST_PORTAL_SCREEN_FAIL],
        id: reservationId,
        screen,
        state,
        promise: (client) => client.post(`/guestportal/controlScreen/reservation/${reservationId}/${screen}?state=${state}`)
    };
}

export function resolveAddress(address) {
    return {
        types: [RESOLVE_ADDRESS, RESOLVE_ADDRESS_SUCCESS, RESOLVE_ADDRESS_FAIL],
        promise: (client) => client.post(`/task/address`, {data: {address}})
    };
}

export function setVerificationStatus(reservationId, status, data) {
    return {
        types: [SET_VERIFICATION_STATUS, SET_VERIFICATION_STATUS_SUCCESS, SET_VERIFICATION_STATUS_FAIL],
        id: reservationId,
        status,
        promise: (client) => client.post(`/reservation/changeVerification/${reservationId}/?supervised=${status}`, {data})
    };
}

export function setVerificationStatusInList(reservationId, status, data, index) {
    return {
        types: [SET_VERIFICATION_STATUS, SET_VERIFICATION_STATUS_SUCCESS, SET_VERIFICATION_STATUS_FAIL],
        id: reservationId,
        status,
        index,
        promise: (client) => client.post(`/reservation/changeVerification/${reservationId}/?supervised=${status}`, {data})
    };
}

export function search(value, options) {
    const query = {
        search: value,
        from: 0,
        size: 10,
        sort: 'check_in_date:desc,_score',
        ...options,
    }
    const querystring = Object.keys(query).map(k => `${k}=${query[k]}`).join('&')
    return {
        types: [SEARCH, SEARCH_SUCCESS, SEARCH_FAIL],
        pagination: query,
        promise: (client) => client.get(`/reservation/search?${querystring}`)
    };
}

export function clearSearch() {
    return {
        type: CLEAR_SEARCH
    }
}

export function batchUpdateReservations(ids = []) {
    return {
        type: UPDATE_BATCH,
        ids: ids,
    };
}

export function popBatchUpdateReservation(id) {
    return {
        type: UPDATE_BATCH_POP,
        id: id,
    };
}

export function patchBatchUpdateReservation(reservationId, patch, index) {
    return {
        type: UPDATE_BATCH_PATCH,
        id: reservationId,
        patch: patch,
        index: index,
    };
}
import apiClient from '../libs/apiClient'

const methods = ['get', 'post', 'put', 'patch', 'delete'];

class ApiClient {
    constructor(req) {
        methods.forEach((method) =>
            this[method] = (path, {params, data, options} = {}) => (getState) => new Promise((resolve, reject) => {

                const authState = (getState) ? getState().auth : null
                const session = (authState && authState.session) ? authState.session : null

                apiClient({method, path, data, params, options, session, req})
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        if (error.response) {
                            // // The request was made and the server responded with a status code
                            // // that falls out of the range of 2xx
                            // console.log(error.response.data);
                            // console.log(error.response.status);
                            // console.log(error.response.headers);
                            reject(error.response.data || error);
                        } else if (error.request) {
                            // // The request was made but no response was received
                            // // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                            // // http.ClientRequest in node.js
                            // console.log(error.request);
                            reject(`The request was made but no response was received from API server: ${error}`);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.error(error.message || error);
                        }
                    });
            }));
    }

    /*
     * There's a V8 bug where, when using Babel, exporting classes with only
     * constructors sometimes fails. Until it's patched, this is a solution to
     * "ApiClient is not defined" from issue #14.
     * https://github.com/erikras/react-redux-universal-hot-example/issues/14
     *
     * Relevant Babel bug (but they claim it's V8): https://phabricator.babeljs.io/T2455
     *
     * Remove it at your own risk.
     */
    empty() {
    }
}

export default ApiClient;
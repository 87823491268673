const nextRoutes = require('next-routes')
const routes = module.exports = nextRoutes()

const APP_ROUTES = [
    {
        page: 'risks',
        name: 'index',
        pattern: '/',
        authRequired: true
    },
    {
        page: 'analytics',
        name: 'analytics',
        pattern: '/analytics',
        authRequired: true
    },
    {
        page: 'billing',
        name: 'billing',
        pattern: '/billing',
        authRequired: true,
        ownerOnly: true,
    },
    {
        page: 'stargate',
        name: 'stargate',
        pattern: '/stargate/:id?',
        authRequired: true
    },
    {
        page: 'buildings',
        name: 'buildings',
        pattern: '/buildings',
        authRequired: true
    },
    {
        page: 'building',
        name: 'building',
        pattern: '/building/:id/:building',
        authRequired: true,
        vendorRequired: true
    },
    {
        page: 'listings',
        name: 'listings',
        pattern: '/listings',
        authRequired: true
    },
    {
        page: 'listing',
        name: 'listing',
        pattern: '/listing/:id',
        authRequired: true
    },
    {
        page: 'reservations',
        name: 'reservations',
        pattern: '/reservations',
        authRequired: true
    },
    {
        page: 'reservation',
        name: 'reservation',
        pattern: '/reservation/:id',
        authRequired: true
    },
    {
        page: 'create-reservation',
        name: 'create-reservation',
        pattern: '/create-reservation',
        authRequired: true
    },
    {
        page: 'create-reservation',
        name: 'edit-reservation',
        pattern: '/edit-reservation/:id',
        authRequired: true
    },
    {
        page: 'guestportal',
        name: 'guestportal',
        pattern: '/guestportal',
        authRequired: true,
        ownerOnly: true,
    },
    {
        page: 'guestportal/screen',
        name: 'guestportal-screen-settings',
        pattern: '/guestportal/screen/:name',
        authRequired: true,
        ownerOnly: true,
    },
    {
        page: 'protect',
        name: 'protect',
        pattern: '/protect',
        authRequired: true,
        ownerOnly: true,
    },
    {
        page: 'settings',
        name: 'settings',
        pattern: '/settings',
        authRequired: true,
        ownerOnly: true,
    },
    {
        page: 'risks',
        name: 'risks',
        pattern: '/risks',
        authRequired: true,
    },
    {
        page: 'accounts',
        name: 'accounts',
        pattern: '/accounts/:id?/:action?',
        authRequired: true,
        ownerOnly: true,
    },
    {
        page: 'download',
        name: 'download',
        pattern: '/download/:type',
        authRequired: true,
    },
    {
        page: 'users',
        name: 'users',
        pattern: '/users',
        authRequired: true,
        adminOnly: true
    },
    {
        page: 'admin/listings',
        name: 'admin listings',
        pattern: '/admin/listings',
        authRequired: true,
        adminOnly: true
    },
    {
        page: 'admin/kibana',
        name: 'admin analytics',
        pattern: '/admin/kibana',
        authRequired: true,
        adminOnly: true
    },
    {
        page: 'logout',
        name: 'logout',
        pattern: '/logout'
    },
    {
        page: 'challenge',
        name: 'login challenge',
        pattern: '/login/challenge'
    },
    {
        page: 'login',
        name: 'login',
        pattern: '/login'
    },
    {
        page: 'connect',
        name: 'connect-pms',
        pattern: '/connect/:pms'
    },

    // password reset
    {
        page: 'account/reset',
        name: 'forgot-password',
        pattern: '/account/reset/:token?'
    },

    // public error page
    {
        page: 'error',
        name: 'error',
        pattern: '/error/:id?',
    },

    // load-balancer health test page
    {
        page: 'health',
        name: 'health',
        pattern: '/health'
    },
]

APP_ROUTES.forEach(route => routes.add(route));

// export list of protected routes that require auth
const ProtectedRoutes = APP_ROUTES.filter(r => (r.authRequired || r.adminOnly || r.ownerOnly || r.vendorRequired));
module.exports.ProtectedRoutes = ProtectedRoutes;
const LOGIN = 'autohost/auth/LOGIN';
export const LOGIN_SUCCESS = 'autohost/auth/LOGIN_SUCCESS';
const LOGIN_FAIL = 'autohost/auth/LOGIN_FAIL';
const LOGIN_CHALLENGE = 'autohost/auth/LOGIN_CHALLENGE';
export const LOGIN_CHALLENGE_SUCCESS = 'autohost/auth/LOGIN_CHALLENGE_SUCCESS';
const LOGIN_CHALLENGE_FAIL = 'autohost/auth/LOGIN_CHALLENGE_FAIL';
const LOGOUT = 'autohost/auth/LOGOUT';
export const LOGOUT_SUCCESS = 'autohost/auth/LOGOUT_SUCCESS';
const LOGOUT_FAIL = 'autohost/auth/LOGOUT_FAIL';
const LOAD_AUTH = 'autohost/auth/LOAD_AUTH';
const LOAD_AUTH_SUCCESS = 'autohost/auth/LOAD_AUTH_SUCCESS';
const LOAD_AUTH_FAIL = 'autohost/auth/LOAD_AUTH_FAIL';
const GET_NOTIFICATIONS = 'autohost/auth/GET_NOTIFICATIONS';
const GET_NOTIFICATIONS_SUCCESS = 'autohost/auth/GET_NOTIFICATIONS_SUCCESS';
const GET_NOTIFICATIONS_FAIL = 'autohost/auth/GET_NOTIFICATIONS_FAIL';
const CLEAR_NOTIFICATION = 'autohost/auth/CLEAR_NOTIFICATION';
const CLEAR_NOTIFICATION_SUCCESS = 'autohost/auth/CLEAR_NOTIFICATION_SUCCESS';
const CLEAR_NOTIFICATION_FAIL = 'autohost/auth/CLEAR_NOTIFICATION_FAIL';
export const SET_AUTH = 'autohost/auth/SET_AUTH';
const UPDATE_SETTINGS = 'autohost/auth/UPDATE_SETTINGS';
const UPDATE_SETTINGS_SUCCESS = 'autohost/auth/UPDATE_SETTINGS_SUCCESS';
const UPDATE_SETTINGS_FAIL = 'autohost/auth/UPDATE_SETTINGS_FAIL';
const START_PASSWORD_RESET = 'autohost/auth/START_PASSWORD_RESET';
const START_PASSWORD_RESET_SUCCESS = 'autohost/auth/START_PASSWORD_RESET_SUCCESS';
const START_PASSWORD_RESET_FAIL = 'autohost/auth/START_PASSWORD_RESET_FAIL';
const FINISH_PASSWORD_RESET = 'autohost/auth/FINISH_PASSWORD_RESET';
const FINISH_PASSWORD_RESET_SUCCESS = 'autohost/auth/FINISH_PASSWORD_RESET_SUCCESS';
const FINISH_PASSWORD_RESET_FAIL = 'autohost/auth/FINISH_PASSWORD_RESET_FAIL';

const initialState = {
    loading: false,
    loaded: false,
    authenticated: false,
    session: null,
    user: {},
    notifications: [],
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                loading: true,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                authenticated: !action.payload.redirect,
                user: {...action.payload},
            };
        case LOGIN_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error,
            };
        case LOGIN_CHALLENGE:
            return {
                ...state,
                loading: true,
            };
        case LOGIN_CHALLENGE_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                authenticated: true,
                user: {...action.payload},
            };
        case LOGIN_CHALLENGE_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error,
            };
        case LOAD_AUTH:
            return {
                ...state,
                loading: true,
            };
        case LOAD_AUTH_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                authenticated: !!action.payload,
                user: {...action.payload},
            };
        case LOAD_AUTH_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error,
            };
        case SET_AUTH:
            return {
                ...state,
                ...action.auth,
            };
        case UPDATE_SETTINGS:
            return state;
        case UPDATE_SETTINGS_SUCCESS:
            return {
                ...state,
                user: {...action.payload},
            };
        case UPDATE_SETTINGS_FAIL:
            return {
                ...state,
                error: action.error,
            };
        case GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notifications: action.payload,
            };
        case CLEAR_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notifications: action.payload,
            };
        case LOGOUT_SUCCESS:
            return {
                ...initialState,
            };
        default:
            return state;
    }
}

export function login(auth) {
    return {
        types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
        promise: (client) =>
            client.post('/login', {
                data: auth,
            }),
    };
}

export function challenge(auth) {
    return {
        types: [LOGIN_CHALLENGE, LOGIN_CHALLENGE_SUCCESS, LOGIN_CHALLENGE_FAIL],
        promise: (client) =>
            client.post('/user/auth/challenge', {
                data: auth,
            }),
    };
}

export function logout() {
    return {
        types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL],
        promise: (client) => client.get('/logout'),
    };
}

export function loadAuth() {
    return {
        types: [LOAD_AUTH, LOAD_AUTH_SUCCESS, LOAD_AUTH_FAIL],
        promise: (client) => client.get(`/loadAuth`),
    };
}

export function setAuth(props) {
    return {
        type: SET_AUTH,
        auth: props,
    };
}

export function updateSettings(data) {
    return {
        types: [UPDATE_SETTINGS, UPDATE_SETTINGS_SUCCESS, UPDATE_SETTINGS_FAIL],
        data: data,
        promise: (client) => client.post('/user/updateIntegrations', {data}),
    };
}

export function updateRiskTolerance(risk) {
    return {
        types: [UPDATE_SETTINGS, UPDATE_SETTINGS_SUCCESS, UPDATE_SETTINGS_FAIL],
        risk: risk,
        promise: (client) => client.post(`/protect/actions/setRiskTolerance?risk=${risk}`),
    };
}

export function startPasswordReset(data) {
    return {
        types: [START_PASSWORD_RESET, START_PASSWORD_RESET_SUCCESS, START_PASSWORD_RESET_FAIL],
        promise: (client) => client.post(`/user/auth/password/reset`, {data}),
    };
}

export function finishPasswordReset(data) {
    return {
        types: [FINISH_PASSWORD_RESET, FINISH_PASSWORD_RESET_SUCCESS, FINISH_PASSWORD_RESET_FAIL],
        promise: (client) => client.post(`/user/auth/password/confirm`, {data}),
    };
}

export function getNotifications(limit = 10) {
    return {
        types: [GET_NOTIFICATIONS, GET_NOTIFICATIONS_SUCCESS, GET_NOTIFICATIONS_FAIL],
        promise: (client) => client.get(`/user/notifications/load?limit=${limit}`),
    };
}

export function clearNotification(id) {
    return {
        id,
        types: [CLEAR_NOTIFICATION, CLEAR_NOTIFICATION_SUCCESS, CLEAR_NOTIFICATION_FAIL],
        promise: (client) => client.post(`/user/notifications/remove/${id}`),
    };
}

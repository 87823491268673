import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import admin from './admin'
import auth from './auth'
import billing from './billing'
import onboard from './onboard'
import reservations from './reservations'
import listings from './listings'
import snackbar from './snackbar'

export default combineReducers({
  form: formReducer,
  admin,
  auth,
  billing,
  onboard,
  listings,
  reservations,
  snackbar,
})

import {datadogRum} from '@datadog/browser-rum';
import CssBaseline from '@material-ui/core/CssBaseline';
import {MuiThemeProvider} from '@material-ui/core/styles';
import flagsmith, {createFlagsmithInstance} from 'flagsmith/isomorphic';
import {FlagsmithProvider} from 'flagsmith/react';
import withRedux from 'next-redux-wrapper';
import App from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Router from 'next/router';
import pathMatch from 'path-match';
import React from 'react';
import {Provider} from 'react-redux';
import {ProtectedRoutes} from '../routes';
import {OutageBanner} from '../src/components/OutageBanner/OutageBanner';
import config from '../src/config';
import ApiClient from '../src/libs/apiClient';
import {eraseCookie, readCookie} from '../src/libs/cookies';
import {setAuth} from '../src/reducers/auth';
import createStore from '../src/store/createStore';
import theme from '../src/theme';

const routeMatch = pathMatch();
const VersionUpgrade = dynamic(() => import('../src/components/VersionUpgrade'), {
    ssr: false,
});

class MyApp extends App {
    static async getInitialProps({Component, ctx}) {
        const isDev = !process.env.STAGE_NAME || !process.env.STAGE_NAME.includes('prod');
        const flagsmithSSR = createFlagsmithInstance();
        await flagsmithSSR.init({
            // fetches flags on the server
            environmentID: config.FLAGSMITH_ENVIRONMENT_KEY,
            api: config.FLAGSMITH_API_URL,
        });

        // try to fetch user from API
        let user = {};
        try {
            const request = {
                method: 'GET',
                path: '/loadAuth',
                req: ctx.req,
            };
            const res = await ApiClient(request);
            if (res && res.data) {
                user = res.data;
            }
        } catch (e) {
            console.error(e);
        }

        // check if current page requires authentication
        const requireAuthentication = ProtectedRoutes.filter((r) => r.authRequired)
            .map((r) => r.pattern)
            .some((pattern) => routeMatch(pattern)(ctx.asPath) !== false);

        // check if current page requires authentication
        const requireAdmin = ProtectedRoutes.filter((r) => r.adminOnly)
            .map((r) => r.pattern)
            .some((pattern) => routeMatch(pattern)(ctx.asPath) !== false);

        // check if current page requires authentication
        const requireOwner = ProtectedRoutes.filter((r) => r.ownerOnly)
            .map((r) => r.pattern)
            .some((pattern) => routeMatch(pattern)(ctx.asPath) !== false);

        // check if current page requires authentication
        const requireVendor = ProtectedRoutes.filter((r) => r.vendorRequired)
            .map((r) => r.pattern)
            .some((pattern) => routeMatch(pattern)(ctx.asPath) !== false);

        // redirect utility
        const redirect = (path, status) => {
            if (ctx.res) {
                ctx.res.writeHead(status, {Location: path});
                ctx.res.end();
                return;
            } else {
                Router.push(path);
            }
        };

        // redirect if page is protected
        const isLogout = ctx.asPath.includes('/logout');
        if (!user.id && requireAuthentication) {
            console.log(`Redirect to login because no user`);
            let next = ctx.asPath;
            if (isLogout) {
                next = '/';
            }
            redirect(`/login?next=${next}`, 302);
        }

        // redirect vendors
        const isVendor = user && user.subtype && user.subtype === 'vendor';
        if (isVendor && !requireVendor && !isLogout && requireAuthentication) {
            console.log(`Redirect to building-not-found because protected path and is vendor`);
            redirect(`/error/building-not-found`, 302);
        }

        // show 404 if protected
        const isOwner = user && user.subtype && ['owner', 'admin'].includes(user.subtype);
        if (!isOwner && requireOwner) {
            console.log(`Redirect to not-allowed because protected path and is subuser`);
            redirect(`/error/not-allowed`, 302);
        }

        // show 404 if protected
        if (user.id && !['admin', 'channel'].includes(user.type) && requireAdmin) {
            console.log(`Redirect to not-found because protected path and is regular user`);
            redirect(`/error/not-found`, 404);
        }

        // redirect if user is missing billing
        const billing = user.billing || {};
        const billingPage = ctx.asPath.includes('/billing');
        let einkesef = ctx.req && ctx.req.headers.cookie && ctx.req.headers.cookie.includes('einkesef');
        if (typeof document !== 'undefined' && document.cookie && !einkesef) {
            einkesef = document.cookie.includes('einkesef');
        }
        if (user.id && !billing.stripe_customer_id && !billingPage && !einkesef && user.type !== 'admin' && !isDev) {
            redirect(`/billing`, 302);
        }

        // logout if user is disabled
        if (user && user.id && !user.is_active) {
            console.log(`Redirect to logout because user is disabled`);
            redirect(`/logout`, 302);
        }

        // grab segment.io object
        const segment = global && global.analytics;

        return {
            pageProps: {
                user,
                segment,
                flagsmithState: flagsmithSSR.getState(),
                ...(Component.getInitialProps ? await Component.getInitialProps(ctx) : {}),
                pathname: ctx.pathname,
            },
        };
    }

    componentDidMount() {
        const {pageProps, store} = this.props;
        const {user} = pageProps;

        // Remove the server-side injected CSS
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles && jssStyles.parentNode) {
            jssStyles.parentNode.removeChild(jssStyles);
        }

        // init DataDog RUM client
        datadogRum.init({
            clientToken: config.DATADOG_RUM_CLIENT_TOKEN,
            applicationId: config.DATADOG_RUM_APP_ID,
            sampleRate: 100,
            env: config.stage,
            version: config.VERSION,
            trackResources: true,
            trackLongTasks: true,
            trackInteractions: true,
            trackFrustrations: true,
            useSecureSessionCookie: config.isProd,
        });
        if (user && user.id) {
            datadogRum.setGlobalContextProperty('usr.id', user.id);
            datadogRum.setGlobalContextProperty('usr.name', user.name);
            datadogRum.setGlobalContextProperty('usr.email', user.email);
        }

        // update auth in state
        if (store) {
            const {
                auth: {authenticated},
            } = store.getState();
            if (user && user.id && !authenticated) {
                store.dispatch(
                    setAuth({
                        user: user,
                        authenticated: !!user.id,
                        session: user.sessionId,
                        loaded: !!user.id,
                    }),
                );
            }
        }

        // register event handler when page is closed to clear cookies
        if (typeof document !== 'undefined') {
            document.addEventListener('beforeunload', () => {
                if (readCookie('autohostSSO') === 'true') {
                    eraseCookie(config.cookieName);
                    eraseCookie('autohostSSO');
                }
            });
        }
    }

    render() {
        const {store, router, pageProps, Component} = this.props;
        const {user} = pageProps;

        return (
            <MuiThemeProvider theme={theme}>
                <Head>
                    <title>Autohost</title>

                    {/* Use minimum-scale=1 to enable GPU rasterization */}
                    <meta
                        name="viewport"
                        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
                    />

                    {/* ask robots not to index this site */}
                    <meta name="googlebot" content="noindex" />
                    <meta name="robots" content="noindex" />
                    <script
                        type="text/javascript"
                        dangerouslySetInnerHTML={{
                            __html: `(function(c,l,a,r,i,t,y){
                                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                                        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                                        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                                    })(window, document, "clarity", "script", "mdk57c7arn");`,
                        }}
                    />
                </Head>
                <CssBaseline />
                <FlagsmithProvider flagsmith={flagsmith} serverState={this.props.pageProps.flagsmithState}>
                    <Provider store={store}>
                        <OutageBanner />
                        <Component router={router} user={user} {...pageProps} />
                        <VersionUpgrade />
                    </Provider>
                </FlagsmithProvider>
            </MuiThemeProvider>
        );
    }
}

// recruitment message
(() => {
    if (typeof window !== 'undefined') {
        console.group('secret');
        console.log('%cAre you a talented web developer?', 'color: purple; font-size: x-large');
        console.log('%cJoin us at Autohost', 'color: purple; font-size: x-large');
        console.log('%chttps://www.autohost.ai/careers', 'color: purple; font-size: large');
        console.groupEnd();
    }
})();

export default withRedux(createStore)(MyApp);

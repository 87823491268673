import palette from '../palette';

export default {
    root: {
        padding: '10px',
        color: palette.text.secondary,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.03)'
        }
    }
};

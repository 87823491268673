const START_ONBOARDING = 'autohost/onboard/START_ONBOARDING';
const START_ONBOARDING_SUCCESS = 'autohost/onboard/START_ONBOARDING_SUCCESS';
const START_ONBOARDING_FAIL = 'autohost/onboard/START_ONBOARDING_FAIL';
const UPDATE_AFTER_ONBOARDING = 'autohost/onboard/UPDATE_AFTER_ONBOARDING';
const UPDATE_AFTER_ONBOARDING_SUCCESS = 'autohost/onboard/UPDATE_AFTER_ONBOARDING_SUCCESS';
const UPDATE_AFTER_ONBOARDING_FAIL = 'autohost/onboard/UPDATE_AFTER_ONBOARDING_FAIL';
const ONBOARDING_STATUS = 'autohost/onboard/ONBOARDING_STATUS';
const ONBOARDING_STATUS_SUCCESS = 'autohost/onboard/ONBOARDING_STATUS_SUCCESS';
const ONBOARDING_STATUS_FAIL = 'autohost/onboard/ONBOARDING_STATUS_FAIL';
const SEND_BILLING_INFO = 'autohost/onboard/SEND_BILLING_INFO';
const SEND_BILLING_INFO_SUCCESS = 'autohost/onboard/SEND_BILLING_INFO_SUCCESS';
const SEND_BILLING_INFO_FAIL = 'autohost/onboard/SEND_BILLING_INFO_FAIL';
const GET_SIGNUP_CONTEXT = 'autohost/onboard/GET_SIGNUP_CONTEXT';
const GET_SIGNUP_CONTEXT_SUCCESS = 'autohost/onboard/GET_SIGNUP_CONTEXT_SUCCESS';
const GET_SIGNUP_CONTEXT_FAIL = 'autohost/onboard/GET_SIGNUP_CONTEXT_FAIL';


const initialState = {
    loading: false,
    loaded: false,
    task: {
        error: null
    }
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case START_ONBOARDING:
            return {
                ...state,
                loading: true
            };
        case START_ONBOARDING_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                task: {...action.payload}
            };
        case START_ONBOARDING_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case GET_SIGNUP_CONTEXT:
            return {
                ...state,
            };
        case GET_SIGNUP_CONTEXT_SUCCESS:
            return {
                ...state,
                context: {...action.payload}
            };
        case GET_SIGNUP_CONTEXT_FAIL:
            return {
                ...state,
                error: action.error
            };
        case ONBOARDING_STATUS:
            return {
                ...state,
                loading: true
            };
        case ONBOARDING_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                task: {...action.payload}
            };
        case ONBOARDING_STATUS_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case UPDATE_AFTER_ONBOARDING:
            return {
                ...state,
                loading: true
            };
        case UPDATE_AFTER_ONBOARDING_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_AFTER_ONBOARDING_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}

export function startGuestyOnboard(data) {
    return {
        types: [START_ONBOARDING, START_ONBOARDING_SUCCESS, START_ONBOARDING_FAIL],
        promise: (client) => client.post('/channel/guesty/onboardUser', {
            data: data
        })
    };
}

export function startRentalsUnitedOnboard(data) {
    return {
        types: [START_ONBOARDING, START_ONBOARDING_SUCCESS, START_ONBOARDING_FAIL],
        promise: (client) => client.post('/channel/rentalsunited/onboardUser', {
            data: data
        })
    };
}

export function startHostAwayOnboard(data) {
    return {
        types: [START_ONBOARDING, START_ONBOARDING_SUCCESS, START_ONBOARDING_FAIL],
        promise: (client) => client.post('/channel/hostaway/onboardUser', {
            data: data
        })
    };
}

export function startCloudbedsOnboard(data) {
    return {
        types: [START_ONBOARDING, START_ONBOARDING_SUCCESS, START_ONBOARDING_FAIL],
        promise: (client) => client.post('/channel/cloudbeds/onboardUser', {
            data: data
        })
    };
}

export function startApaleoOnboard(data) {
    return {
        types: [START_ONBOARDING, START_ONBOARDING_SUCCESS, START_ONBOARDING_FAIL],
        promise: (client) => client.post('/channel/apaleo/onboardUser', {
            data: data
        })
    };
}

export function startMyvrOnboard(data) {
    return {
        types: [START_ONBOARDING, START_ONBOARDING_SUCCESS, START_ONBOARDING_FAIL],
        promise: (client) => client.post('/channel/myvr/onboardUser', {
            data: data
        })
    };
}

export function startBeds24Onboard(data) {
    return {
        types: [START_ONBOARDING, START_ONBOARDING_SUCCESS, START_ONBOARDING_FAIL],
        promise: (client) => client.post('/channel/beds24/onboardUser', {
            data: data
        })
    };
}

export function startSmartbnbOnboard(data) {
    return {
        types: [START_ONBOARDING, START_ONBOARDING_SUCCESS, START_ONBOARDING_FAIL],
        promise: (client) => client.post('/channel/smartbnb/onboardUser', {
            data: data
        })
    };
}

export function startHostfullyOnboard(data) {
    return {
        types: [START_ONBOARDING, START_ONBOARDING_SUCCESS, START_ONBOARDING_FAIL],
        promise: (client) => client.post('/channel/hostfully/onboardUser', {
            data: data
        })
    };
}

export function startImpalaOnboard(data) {
    return {
        types: [START_ONBOARDING, START_ONBOARDING_SUCCESS, START_ONBOARDING_FAIL],
        promise: (client) => client.post('/channel/impala/onboardUser', {
            data: data
        })
    };
}

export function startOperaOnboard(data) {
    return {
        types: [START_ONBOARDING, START_ONBOARDING_SUCCESS, START_ONBOARDING_FAIL],
        promise: (client) => client.post('/channel/opera/onboardUser', {
            data: data
        })
    };
}

export function startStreamlineOnboard(data) {
    return {
        types: [START_ONBOARDING, START_ONBOARDING_SUCCESS, START_ONBOARDING_FAIL],
        promise: (client) => client.post('/channel/streamline/onboardUser', {
            data: data
        })
    };
}

export function startTrackOnboard(data) {
    return {
        types: [START_ONBOARDING, START_ONBOARDING_SUCCESS, START_ONBOARDING_FAIL],
        promise: (client) => client.post('/channel/trackhs/onboardUser', {
            data: data
        })
    };
}

export function startEscapiaOnboard(data) {
    return {
        types: [START_ONBOARDING, START_ONBOARDING_SUCCESS, START_ONBOARDING_FAIL],
        promise: (client) => client.post('/channel/escapia/onboardUser', {
            data: data
        })
    };
}

export function startStayntouchOnboard(data) {
    return {
        types: [START_ONBOARDING, START_ONBOARDING_SUCCESS, START_ONBOARDING_FAIL],
        promise: (client) => client.post('/channel/stayntouch/onboardUser', {
            data: data
        })
    };
}

export function startLavandaOnboard(data) {
    return {
        types: [START_ONBOARDING, START_ONBOARDING_SUCCESS, START_ONBOARDING_FAIL],
        promise: (client) => client.post('/channel/lavanda/onboardUser', {
            data: data
        })
    };
}

export function startInforhmsOnboard(data) {
    return {
        types: [START_ONBOARDING, START_ONBOARDING_SUCCESS, START_ONBOARDING_FAIL],
        promise: (client) => client.post('/channel/inforhms/onboardUser', {
            data: data
        })
    };
}

export function startBookingOnboard(data) {
    return {
        types: [START_ONBOARDING, START_ONBOARDING_SUCCESS, START_ONBOARDING_FAIL],
        promise: (client) => client.post('/channel/booking/onboardUser', {
            data: data
        })
    };
}

export function checkTaskStatus(task) {
    return {
        types: [ONBOARDING_STATUS, ONBOARDING_STATUS_SUCCESS, ONBOARDING_STATUS_FAIL],
        promise: (client) => client.get(`/task/status?id=${task.id}&type=${task.type}&name=${task.user_id}`)
    };
}

export function updateAfterIntegration(data, task) {
    return {
        types: [UPDATE_AFTER_ONBOARDING, UPDATE_AFTER_ONBOARDING_SUCCESS, UPDATE_AFTER_ONBOARDING_FAIL],
        promise: (client) => client.post(`/user/updateAfterIntegration?id=${task.id}&type=${task.type}&name=${task.user_id}`, {
            data: data
        })
    };
}

export function updateOauthConnection(data, pms) {
    return {
        types: [UPDATE_AFTER_ONBOARDING, UPDATE_AFTER_ONBOARDING_SUCCESS, UPDATE_AFTER_ONBOARDING_FAIL],
        promise: (client) => client.post(`/user/updateOauthConnection?pms=${pms}`, {data})
    };
}

export function sendStripeToken(id, data) {
    return {
        types: [SEND_BILLING_INFO, SEND_BILLING_INFO_SUCCESS, SEND_BILLING_INFO_FAIL],
        promise: (client) => client.post(`/user/billing/create/${id}`, {data})
    };
}

export function updateStripeToken(id, data) {
    return {
        types: [SEND_BILLING_INFO, SEND_BILLING_INFO_SUCCESS, SEND_BILLING_INFO_FAIL],
        promise: (client) => client.post(`/user/billing/updateCard/${id}`, {data})
    };
}

export function getSignupContextFromToken(token) {
    return {
        types: [SEND_BILLING_INFO, SEND_BILLING_INFO_SUCCESS, SEND_BILLING_INFO_FAIL],
        promise: (client) => client.get(`/user/accounts/signupLinkTokenLookup/${token}`)
    };
}
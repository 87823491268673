import {getTableRowsPerPage} from '../libs/personalize';


const LIST_USERS = 'autohost/admin/LIST_USERS';
const LIST_USERS_SUCCESS = 'autohost/admin/LIST_USERS_SUCCESS';
const LIST_USERS_FAIL = 'autohost/admin/LIST_USERS_FAIL';
const EDIT_USER = 'autohost/admin/EDIT_USER';
const EDIT_USER_SUCCESS = 'autohost/admin/EDIT_USER_SUCCESS';
const EDIT_USER_FAIL = 'autohost/admin/EDIT_USER_FAIL';
const SEARCH = 'autohost/admin/SEARCH_USERS';
const SEARCH_SUCCESS = 'autohost/admin/SEARCH_USERS_SUCCESS';
const SEARCH_FAIL = 'autohost/admin/SEARCH_USERS_FAIL';
const SELECT_USER = 'autohost/admin/SELECT_USER';
const UNSELECT_USER = 'autohost/admin/UNSELECT_USER';

const initialState = {
    loading: false,
    loaded: false,
    selected: null,
    users: [],
    search: [],
    pagination: {
        // size: 10,
        sort: 'created_at:desc'
    }
};

const calcPages = (total, size) => Math.ceil(total / size);
const calcPage = (total, size, from) => Math.ceil(total / size) - Math.ceil((total - from) / size);

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case LIST_USERS:
            return {
                ...state,
                loading: true
            };
        case LIST_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                authenticated: true,
                isEmpty: action.payload.length === 0,
                users: action.payload.items,
                pagination: {
                    ...action.pagination,
                    sort: action.pagination.sort || state.pagination.sort,
                    total: Number(action.payload.total),
                    size: Number(action.payload.size),
                    from: Number(action.payload.from),
                    pages: calcPages(action.payload.total, action.payload.size),
                    page: calcPage(action.payload.total, action.payload.size, action.payload.from),
                }
            };
        case LIST_USERS_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case EDIT_USER:
            return {
                ...state,
            };
        case EDIT_USER_SUCCESS:
            return {
                ...state,
                users: state.users.map((user, index) => (index === action.userIndex) ? ({...user, ...action.payload}) : user),
            };
        case EDIT_USER_FAIL:
            return {
                ...state,
                error: action.error
            };
        case SEARCH_SUCCESS:
            return {
                ...state,
                search: action.payload.items
            };
        case SELECT_USER:
            return {
                ...state,
                selected: action.user
            };
        case UNSELECT_USER:
            return {
                ...state,
                selected: null
            };
        default:
            return state;
    }
}

export function loadUsers(pagination, other) {
    const query = {
        from: 0,
        size: getTableRowsPerPage('users'),
        ...pagination,
        ...other
    };
    const querystring = Object.keys(query).map(k => `${k}=${query[k]}`).join('&');
    return {
        types: [LIST_USERS, LIST_USERS_SUCCESS, LIST_USERS_FAIL],
        pagination: query,
        promise: (client) => client.get(`/admin/user/load?${querystring}`)
    };
}

export function loadChannelAccounts(pagination, other) {
    const query = {...pagination, ...other};
    const querystring = Object.keys(query).map(k => `${k}=${query[k]}`).join('&');
    return {
        types: [LIST_USERS, LIST_USERS_SUCCESS, LIST_USERS_FAIL],
        pagination,
        promise: (client) => client.get(`/user/accounts/load?${querystring}`)
    };
}

export function searchUsers(value, options, type = 'search') {
    const query = {
        [type]: value,
        size: 10,
        sort: '_score,created_at:desc',
        ...options,
    };
    const querystring = Object.keys(query).map(k => `${k}=${query[k]}`).join('&');
    return {
        types: [SEARCH, SEARCH_SUCCESS, SEARCH_FAIL],
        pagination: query,
        promise: (client) => client.get(`/user/accounts/search?${querystring}`)
    };
}

export function editUser(userIndex, userId, action, data) {
    return {
        types: [EDIT_USER, EDIT_USER_SUCCESS, EDIT_USER_FAIL],
        userIndex,
        userId,
        action,
        promise: (client) => client.post(`/admin/user/update/${userId}/${action}`, {data})
    };
}

export function editChannelAccount(userIndex, userId, action, data) {
    return {
        types: [EDIT_USER, EDIT_USER_SUCCESS, EDIT_USER_FAIL],
        userIndex,
        userId,
        action,
        promise: (client) => client.post(`/user/accounts/update/${userId}/${action}`, {data})
    };
}

export function selectUser(user) {
    return {
        type: SELECT_USER,
        user
    };
}

export function unselectUser() {
    return {
        type: UNSELECT_USER
    };
}
const OPEN = 'autohost/snackbar/OPEN';
const CLOSE = 'autohost/snackbar/CLOSE';
const initialState = {
    open: false,
    text: null,
    action: null,
    props: {}
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case OPEN:
            return {
                ...state,
                text: action.text,
                action: action.action,
                props: action.props || {},
                open: true
            };
        case CLOSE:
            return {
                ...state,
                text: null,
                action: null,
                props: {},
                open: false
            };
        default:
            return state;
    }
}

export function openSnackbar(text, action, props = {}) {
    if (Array.isArray(text)) {
        props = text[2] || {};
        action = text[1] || null;
        text = text[0];
    }
    return {
        type: OPEN,
        text,
        action,
        props
    };
}

export function closeSnackbar() {
    return {
        type: CLOSE
    };
}
// Palette
import palette from '../palette';

export default {
    outlined: {},
    contained: {
        backgroundColor: palette.common.white,
        '&:hover': {
            backgroundColor: palette.common.neutral
        }
    }
};

export const setTableRowsPerPage = (value, tableName = 'default') => {
    if (typeof localStorage !== 'undefined') {
        localStorage.setItem(`${tableName}-rows-per-page`, value)
    }
};

export const getTableRowsPerPage = (tableName = 'default', defaultValue = 10) => {
    if (typeof localStorage !== 'undefined') {
        return Number(localStorage.getItem(`${tableName}-rows-per-page`) || defaultValue);
    }
    return Number(defaultValue)
};
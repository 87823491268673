const axios = require('axios');
import config from '../config'
import {readCookie} from './cookies'


const client = async (props) => {

    const {method, path, data, params, options, session, req, ...rest} = props;

    // remove leading slash from path
    let normalizedPath = path;
    if (path.substring(0, 1) === '/') {
        normalizedPath = path.substring(1, path.length);
    }

    // axios request options
    let request = {
        url: `${config.autohostApiEndpoint}/${normalizedPath}`,
        method: method,
        data: data,
        params: params,
        // timeout: 5000,
        // withCredentials: true,
        ...options,
        headers: options && options.headers || {}
    };

    // try to extract auth token from cookies
    let cookie
    if (req) {
        cookie = readCookie(config.cookieName, req.headers.cookie)
    }
    if (process.browser) {
        cookie = readCookie(config.cookieName)
    }
    if (session) {
        cookie = session
    }
    if (cookie) {
        request.headers['Authorization'] = cookie
    }

    // remove headers if empty
    if (Object.keys(request.headers).length === 0) {
        delete request['headers'];
    }

    // send API request
    try {
        return await axios(request);
    } catch (e) {
        console.error(`API error for [${request.method}] \`${path}\`: ${e}`);
        throw e;
    }
};

export default client;
import Immutable from 'immutable';
import {applyMiddleware, compose, createStore} from 'redux';
import {createLogger} from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import config from '../config';
import rootReducer from '../reducers';
import apiClientMiddleware from './middlewares/apiClientMiddleware';
import {authMiddleware} from './middlewares/authMiddleware';
import storeApiClient from './storeApiClient';

function createMiddlewares({isServer, req}) {
    const apiClient = new storeApiClient(req);

    let middlewares = [thunkMiddleware, apiClientMiddleware(apiClient), authMiddleware];

    if (config.env === 'development' && typeof window !== 'undefined') {
        middlewares.push(
            createLogger({
                level: 'info',
                collapsed: true,
                stateTransformer: (state) => {
                    let newState = {};

                    for (let i of Object.keys(state)) {
                        if (Immutable.Iterable.isIterable(state[i])) {
                            newState[i] = state[i].toJS();
                        } else {
                            newState[i] = state[i];
                        }
                    }

                    return newState;
                },
            }),
        );
    }

    return middlewares;
}

function immutableChildren(obj) {
    let state = {};
    Object.keys(obj).forEach((key) => {
        state[key] = Immutable.fromJS(obj[key]);
    });
    return state;
}

const create = (initialState = {}, context) => {
    let {isServer, req} = context;
    let middlewares = createMiddlewares({isServer, req});
    // let state = immutableChildren(initialState)
    let state = initialState;
    const enhancer =
        !isServer && window.__REDUX_DEVTOOLS_EXTENSION__ && config.env !== 'production'
            ? compose(applyMiddleware(...middlewares), window.__REDUX_DEVTOOLS_EXTENSION__())
            : compose(applyMiddleware(...middlewares));

    return createStore(rootReducer, state, enhancer);
};

export default create;

const GET = 'autohost/listings/GET';
const GET_SUCCESS = 'autohost/listings/GET_SUCCESS';
const GET_FAIL = 'autohost/listings/GET_FAIL';
const GET_STATS = 'autohost/listings/GET_STATS';
const GET_STATS_SUCCESS = 'autohost/listings/GET_STATS_SUCCESS';
const GET_STATS_FAIL = 'autohost/listings/GET_STATS_FAIL';
const UPDATE = 'autohost/listings/UPDATE';
const UPDATE_SUCCESS = 'autohost/listings/UPDATE_SUCCESS';
const UPDATE_FAIL = 'autohost/listings/UPDATE_FAIL';
const LOAD = 'autohost/listings/LOAD';
const LOAD_SUCCESS = 'autohost/listings/LOAD_SUCCESS';
const LOAD_FAIL = 'autohost/listings/LOAD_FAIL';
const SEARCH = 'autohost/listings/SEARCH';
const SEARCH_SUCCESS = 'autohost/listings/SEARCH_SUCCESS';
const SEARCH_FAIL = 'autohost/listings/SEARCH_FAIL';
const CLEAR_SEARCH = 'autohost/listings/CLEAR_SEARCH';
const UPDATE_STATUS = 'autohost/listings/UPDATE_STATUS';
const UPDATE_STATUS_SUCCESS = 'autohost/listings/UPDATE_STATUS_SUCCESS';
const UPDATE_STATUS_FAIL = 'autohost/listings/UPDATE_STATUS_FAIL';
const CONTROL_GUEST_PORTAL_SCREEN = 'autohost/listings/CONTROL_GUEST_PORTAL_SCREEN';
const CONTROL_GUEST_PORTAL_SCREEN_SUCCESS = 'autohost/listings/CONTROL_GUEST_PORTAL_SCREEN_SUCCESS';
const CONTROL_GUEST_PORTAL_SCREEN_FAIL = 'autohost/listings/CONTROL_GUEST_PORTAL_SCREEN_FAIL';
const LOAD_BUILDINGS = 'autohost/listings/LOAD_BUILDINGS';
const LOAD_BUILDINGS_SUCCESS = 'autohost/listings/LOAD_BUILDINGS_SUCCESS';
const LOAD_BUILDINGS_FAIL = 'autohost/listings/LOAD_BUILDINGS_FAIL';
const UPDATE_BATCH = 'autohost/listings/UPDATE_BATCH';
const UPDATE_BATCH_POP = 'autohost/listings/UPDATE_BATCH_POP';
const UPDATE_BATCH_PATCH = 'autohost/listings/UPDATE_BATCH_PATCH';

import {getTableRowsPerPage} from '../libs/personalize';

const initialState = {
    loading: false,
    loaded: false,
    listing: null,
    batch: [],
    listings: [],
    buildings: [],
    pagination: {
        from: 0,
        total: 0,
        sort: 'nickname:asc'
    },
    search: [],
    stats: {},
};

let updatedListings;
const calcPages = (total, size) => Math.ceil(total / size);
const calcPage = (total, size, from) => Math.ceil(total / size) - Math.ceil((total - from) / size);

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET:
            return {
                ...state,
                selected: null,
                loading: true
            };
        case GET_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                listing: action.payload,
                error: null
            };
        case GET_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                listing: null,
                error: action.error
            };
        case UPDATE_BATCH:
            return {
                ...state,
                batch: action.ids,
                error: null
            };
        case UPDATE_BATCH_POP:
            return {
                ...state,
                batch: (state.batch || []).filter(id => id !== action.id),
            };
        case UPDATE_BATCH_PATCH:
            updatedListings = JSON.parse(JSON.stringify(state.listings)) // fresh copy to avoid mutations
            updatedListings[action.index] = {
                ...updatedListings[action.index],
                ...action.patch,
            }
            return {
                ...state,
                listings: updatedListings
            };
        case UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                listing: action.payload,
                error: null
            };
        case UPDATE_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case GET_STATS_SUCCESS:
            return {
                ...state,
                stats: action.payload
            };
        case LOAD:
            return {
                ...state,
                loading: true
            };
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                listings: action.payload.items,
                pagination: {
                    ...action.pagination,
                    sort: action.pagination.sort || state.pagination.sort,
                    total: Number(action.payload.total),
                    size: Number(action.payload.size),
                    from: Number(action.payload.from),
                    pages: calcPages(action.payload.total, action.payload.size),
                    page: calcPage(action.payload.total, action.payload.size, action.payload.from),
                },
                error: null
            };
        case LOAD_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case LOAD_BUILDINGS_SUCCESS:
            return {
                ...state,
                buildings: action.payload.aggregations.buildings.buckets,
                error: null
            };
        case LOAD_BUILDINGS_FAIL:
            return {
                ...state,
                error: action.error
            };
        case UPDATE_STATUS_SUCCESS:
            updatedListings = JSON.parse(JSON.stringify(state.listings)) // fresh copy to avoid mutations
            updatedListings[action.index].status = action.status
            return {
                ...state,
                listings: updatedListings
            };
        case CONTROL_GUEST_PORTAL_SCREEN_SUCCESS:
            return {
                ...state,
                listing: action.payload,
                error: null
            };
        case SEARCH_SUCCESS:
            return {
                ...state,
                search: action.payload.items
            }
        case CLEAR_SEARCH:
            return {
                ...state,
                search: []
            }
        default:
            return state;
    }
}

export function getListing(id) {
    return {
        types: [GET, GET_SUCCESS, GET_FAIL],
        id: id,
        promise: (client) => client.get(`/listing/get/${id}`)
    };
}

export function batchUpdateListings(ids = []) {
    return {
        type: UPDATE_BATCH,
        ids: ids,
    };
}

export function popBatchUpdateListing(id) {
    return {
        type: UPDATE_BATCH_POP,
        id: id,
    };
}

export function patchBatchUpdateListing(listingId, patch, index) {
    return {
        type: UPDATE_BATCH_PATCH,
        id: listingId,
        patch: patch,
        index: index,
    };
}

export function loadBuildings() {
    return {
        types: [LOAD_BUILDINGS, LOAD_BUILDINGS_SUCCESS, LOAD_BUILDINGS_FAIL],
        promise: (client) => client.get(`/listing/load/?buildings=1&noitems=1`)
    };
}

export function updateListing(id, patch) {
    return {
        types: [GET, GET_SUCCESS, GET_FAIL],
        id: id,
        promise: (client) => client.post(`/listing/update/${id}`, {data: patch})
    };
}

export function loadListings(pagination) {
    const query = {
        from: 0,
        size: getTableRowsPerPage('listings'),
        ...pagination,
    }
    const querystring = Object.keys(query).map(k => `${k}=${query[k]}`).join('&')
    return {
        types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
        pagination: query,
        promise: (client) => client.get(`/listing/load?${querystring}`)
    };
}

export function changeListingStatus(listingId, status, index) {
    const data = {status: status}
    return {
        types: [UPDATE_STATUS, UPDATE_STATUS_SUCCESS, UPDATE_STATUS_FAIL],
        id: listingId,
        status,
        index,
        promise: (client) => client.post(`/listing/update/${listingId}`, {data})
    };
}

export function controlGuestPortalScreen(listingId, screen, state) {
    return {
        types: [CONTROL_GUEST_PORTAL_SCREEN, CONTROL_GUEST_PORTAL_SCREEN_SUCCESS, CONTROL_GUEST_PORTAL_SCREEN_FAIL],
        id: listingId,
        screen,
        state,
        promise: (client) => client.post(`/guestportal/controlScreen/listing/${listingId}/${screen}?state=${state}`)
    };
}

export function getStats(params) {
    const query = {
        ...params,
    }
    const querystring = Object.keys(query).map(k => `${k}=${query[k]}`).join('&')
    return {
        types: [GET_STATS, GET_STATS_SUCCESS, GET_STATS_FAIL],
        promise: (client) => client.get(`/listing/stats?${querystring}`)
    };
}

export function search(value, options) {
    const query = {
        search: value,
        size: 10,
        sort: 'created_at:desc,_score',
        ...options,
    }
    const querystring = Object.keys(query).map(k => `${k}=${query[k]}`).join('&')
    return {
        types: [SEARCH, SEARCH_SUCCESS, SEARCH_FAIL],
        pagination: query,
        promise: (client) => client.get(`/listing/load?${querystring}`)
    };
}

export function clearSearch() {
    return {
        type: CLEAR_SEARCH
    }
}